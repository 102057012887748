<template>
  <div class="doc-info">
    <b-form-group :label="$t('g.store_name')" label-for="store-name">
      <validation-provider
        #default="{ errors }"
        name="Store name"
        vid="store_name"
        rules="required"
      >
        <b-form-input
          id="store-name"
          v-model="form_data.store_name"
          :state="errors.length > 0 ? false : null"
          name="store-name"
          :placeholder="$t('g.store_name')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('g.logo')" label-for="logo" class="mb-2">
          <validation-provider #default="{ errors }" name="logo" rules="">
            <div class="image-input-area">
              <b-form-file
                class="image-input"
                ref="logo"
                v-model="form_data.logo"
                accept="image/*"
              />
              <div
                class="image-icon"
                :style="{
                  overflow: form_data.logo ? 'hidden' : '',
                  maxWidth: form_data.logo ? '100%' : '',
                  maxHeight: form_data.logo ? '100%' : '',
                }"
              >
                <img
                  v-if="!form_data.logo || form_data.logo.length == 0"
                  :src="imageInputIcon"
                  alt="Logo"
                />
                <div
                  v-if="!form_data.logo || form_data.logo.length == 0"
                  class="icon-circle"
                >
                  <img :src="plusIcon" alt="plus icon" />
                </div>
                <img v-if="form_data.logo" :src="logoUrl" alt="Logo" />
              </div>
              <p
                v-if="!form_data.logo || form_data.logo.length == 0"
                class="image-text mb-0"
              >
                {{ $t("g.upload_photo") }}
              </p>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('g.cover')" class="mb-2">
          <validation-provider #default="{ errors }" name="cover" rules="">
            <div class="image-input-area">
              <b-form-file
                class="image-input"
                ref="header"
                v-model="form_data.header"
                accept="image/*"
              />
              <div
                class="image-icon"
                :style="{
                  overflow: form_data.header ? 'hidden' : '',
                  maxWidth: form_data.header ? '100%' : '',
                  maxHeight: form_data.header ? '100%' : '',
                }"
              >
                <img
                  v-if="!form_data.header || form_data.header.length == 0"
                  :src="imageInputIcon"
                  alt="Cover"
                />
                <div
                  v-if="!form_data.header || form_data.header.length == 0"
                  class="icon-circle"
                >
                  <img :src="plusIcon" alt="plus icon" />
                </div>
                <img v-if="form_data.header" :src="headerUrl" alt="plus icon" />
              </div>
              <p
                v-if="!form_data.header || form_data.header.length == 0"
                class="image-text mb-0"
              >
                {{ $t("g.upload_photo") }}
              </p>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="$t('g.about')" label-for="about">
      <validation-provider
        #default="{ errors }"
        name="About"
        vid="about"
        rules=""
      >
        <b-form-textarea
          id="about"
          v-model="form_data.about"
          :state="errors.length > 0 ? false : null"
          name="about"
          :placeholder="$t('g.about')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormTextarea,
    BFormFile,
  },
  props: ["form_data"],
  data() {
    return {
      plusIcon: require("@/assets/images/auth/image_input_icon2.svg"),
      imageInputIcon: require("@/assets/images/auth/image_input_icon.svg"),
      editIcon: require("@/assets/images/icons/pencil.svg"),
      imageIcon: require("@/assets/images/icons/photo.png"),
      logoUrl: null,
      headerUrl: null,
    };
  },
  methods: {
    editImage(item) {
      if (item === "header") {
        this.form_data.header = null;
      } else {
        this.form_data.logo = null;
      }
    },
  },
  watch: {
    "form_data.header": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.headerUrl = URL.createObjectURL(val);
      }
    },
    "form_data.logo": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.logoUrl = URL.createObjectURL(val);
      }
    },
  },
};
</script>
