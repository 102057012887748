var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"doc"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.commercial_number'),"label-for":"commercial_number"}},[_c('validation-provider',{attrs:{"name":"Commercial number","vid":"commercial_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"commercial_number","state":errors.length > 0 ? false : null,"name":"commercial_number","placeholder":_vm.$t('g.commercial_number')},model:{value:(_vm.form_data.commercial_number),callback:function ($$v) {_vm.$set(_vm.form_data, "commercial_number", $$v)},expression:"form_data.commercial_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"p-0",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.commercial_expiry_date')}},[_c('validation-provider',{attrs:{"name":"expiration date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.id_expiry_date),callback:function ($$v) {_vm.$set(_vm.form_data, "id_expiry_date", $$v)},expression:"form_data.id_expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.id_number'),"label-for":"id_number"}},[_c('validation-provider',{attrs:{"name":"ID Number","vid":"id_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id_number","type":"number","name":"id_number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.id_number')},model:{value:(_vm.form_data.id_number),callback:function ($$v) {_vm.$set(_vm.form_data, "id_number", $$v)},expression:"form_data.id_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.commercial_number_image')}},[_c('validation-provider',{attrs:{"name":"Commercial number image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"header",staticClass:"image-input",attrs:{"accept":"image/*"},model:{value:(_vm.form_data.commercial_number_image),callback:function ($$v) {_vm.$set(_vm.form_data, "commercial_number_image", $$v)},expression:"form_data.commercial_number_image"}}),_c('div',{staticClass:"image-icon",staticStyle:{"overflow":"hidden"},style:({
                maxWidth: _vm.form_data.commercial_number_image ? '100%' : '',
                maxHeight: _vm.form_data.commercial_number_image ? '100%' : '',
              })},[(
                  !_vm.form_data.commercial_number_image ||
                  _vm.form_data.commercial_number_image.length == 0
                )?_c('img',{attrs:{"src":_vm.idCardIcon,"alt":"Cover"}}):_c('img',{attrs:{"src":_vm.commercialUrl,"alt":"Cover"}})]),(
                !_vm.form_data.commercial_number_image ||
                _vm.form_data.commercial_number_image.length == 0
              )?_c('p',{staticClass:"image-text mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.id_number_image')}},[_c('validation-provider',{attrs:{"name":"id number image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"id_number_image",staticClass:"image-input",attrs:{"state":errors.length > 0 ? false : null,"accept":"image/*"},model:{value:(_vm.form_data.id_number_image),callback:function ($$v) {_vm.$set(_vm.form_data, "id_number_image", $$v)},expression:"form_data.id_number_image"}}),_c('div',{staticClass:"image-icon",staticStyle:{"overflow":"hidden"},style:({
                maxWidth: _vm.form_data.id_number_image ? '100%' : '',
                maxHeight: _vm.form_data.id_number_image ? '100%' : '',
              })},[(
                  !_vm.form_data.id_number_image ||
                  _vm.form_data.id_number_image.length == 0
                )?_c('img',{attrs:{"src":_vm.idCardIcon,"alt":"Cover"}}):_c('img',{attrs:{"src":_vm.idUrl,"alt":"Cover"}})]),(
                !_vm.form_data.id_number_image ||
                _vm.form_data.id_number_image.length == 0
              )?_c('p',{staticClass:"image-text mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"250px","margin-bottom":"30px"},attrs:{"center":{ lat: _vm.form_data.lat, lng: _vm.form_data.lng },"zoom":7,"map-type-id":"terrain","options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false,
        }},on:{"click":_vm.mark}},[_c('GmapMarker',{attrs:{"position":{ lat: _vm.form_data.lat, lng: _vm.form_data.lng },"clickable":true},on:{"click":_vm.cleanMark}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }