var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"doc-info"},[_c('b-form-group',{attrs:{"label":_vm.$t('g.store_name'),"label-for":"store-name"}},[_c('validation-provider',{attrs:{"name":"Store name","vid":"store_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"store-name","state":errors.length > 0 ? false : null,"name":"store-name","placeholder":_vm.$t('g.store_name')},model:{value:(_vm.form_data.store_name),callback:function ($$v) {_vm.$set(_vm.form_data, "store_name", $$v)},expression:"form_data.store_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.logo'),"label-for":"logo"}},[_c('validation-provider',{attrs:{"name":"logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"logo",staticClass:"image-input",attrs:{"accept":"image/*"},model:{value:(_vm.form_data.logo),callback:function ($$v) {_vm.$set(_vm.form_data, "logo", $$v)},expression:"form_data.logo"}}),_c('div',{staticClass:"image-icon",style:({
                overflow: _vm.form_data.logo ? 'hidden' : '',
                maxWidth: _vm.form_data.logo ? '100%' : '',
                maxHeight: _vm.form_data.logo ? '100%' : '',
              })},[(!_vm.form_data.logo || _vm.form_data.logo.length == 0)?_c('img',{attrs:{"src":_vm.imageInputIcon,"alt":"Logo"}}):_vm._e(),(!_vm.form_data.logo || _vm.form_data.logo.length == 0)?_c('div',{staticClass:"icon-circle"},[_c('img',{attrs:{"src":_vm.plusIcon,"alt":"plus icon"}})]):_vm._e(),(_vm.form_data.logo)?_c('img',{attrs:{"src":_vm.logoUrl,"alt":"Logo"}}):_vm._e()]),(!_vm.form_data.logo || _vm.form_data.logo.length == 0)?_c('p',{staticClass:"image-text mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.cover')}},[_c('validation-provider',{attrs:{"name":"cover","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"header",staticClass:"image-input",attrs:{"accept":"image/*"},model:{value:(_vm.form_data.header),callback:function ($$v) {_vm.$set(_vm.form_data, "header", $$v)},expression:"form_data.header"}}),_c('div',{staticClass:"image-icon",style:({
                overflow: _vm.form_data.header ? 'hidden' : '',
                maxWidth: _vm.form_data.header ? '100%' : '',
                maxHeight: _vm.form_data.header ? '100%' : '',
              })},[(!_vm.form_data.header || _vm.form_data.header.length == 0)?_c('img',{attrs:{"src":_vm.imageInputIcon,"alt":"Cover"}}):_vm._e(),(!_vm.form_data.header || _vm.form_data.header.length == 0)?_c('div',{staticClass:"icon-circle"},[_c('img',{attrs:{"src":_vm.plusIcon,"alt":"plus icon"}})]):_vm._e(),(_vm.form_data.header)?_c('img',{attrs:{"src":_vm.headerUrl,"alt":"plus icon"}}):_vm._e()]),(!_vm.form_data.header || _vm.form_data.header.length == 0)?_c('p',{staticClass:"image-text mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('g.about'),"label-for":"about"}},[_c('validation-provider',{attrs:{"name":"About","vid":"about","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"about","state":errors.length > 0 ? false : null,"name":"about","placeholder":_vm.$t('g.about')},model:{value:(_vm.form_data.about),callback:function ($$v) {_vm.$set(_vm.form_data, "about", $$v)},expression:"form_data.about"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }