<template>
  <div class="doc">
    <b-row>
      <b-col md="4">
        <b-form-group
          :label="$t('g.commercial_number')"
          label-for="commercial_number"
        >
          <validation-provider
            #default="{ errors }"
            name="Commercial number"
            vid="commercial_number"
            rules="required"
          >
            <b-form-input
              type="number"
              id="commercial_number"
              v-model="form_data.commercial_number"
              :state="errors.length > 0 ? false : null"
              name="commercial_number"
              :placeholder="$t('g.commercial_number')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4" class="p-0">
        <b-form-group :label="$t('g.commercial_expiry_date')">
          <validation-provider
            #default="{ errors }"
            name="expiration date"
            rules="required"
          >
            <b-form-datepicker
              :state="errors.length > 0 ? false : null"
              v-model="form_data.id_expiry_date"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :label="$t('g.id_number')" label-for="id_number">
          <validation-provider
            #default="{ errors }"
            name="ID Number"
            vid="id_number"
            rules="required"
          >
            <b-form-input
              id="id_number"
              type="number"
              v-model="form_data.id_number"
              name="id_number"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('g.id_number')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('g.commercial_number_image')" class="mb-2">
          <validation-provider
            #default="{ errors }"
            name="Commercial number image"
          >
            <div class="image-input-area">
              <b-form-file
                class="image-input"
                ref="header"
                v-model="form_data.commercial_number_image"
                accept="image/*"
              />
              <div
                class="image-icon"
                style="overflow:hidden"
                :style="{
                  maxWidth: form_data.commercial_number_image ? '100%' : '',
                  maxHeight: form_data.commercial_number_image ? '100%' : '',
                }"
              >
                <img
                  v-if="
                    !form_data.commercial_number_image ||
                    form_data.commercial_number_image.length == 0
                  "
                  :src="idCardIcon"
                  alt="Cover"
                />

                <img v-else :src="commercialUrl" alt="Cover" />
              </div>
              <p
                v-if="
                  !form_data.commercial_number_image ||
                  form_data.commercial_number_image.length == 0
                "
                class="image-text mb-0"
              >
                {{ $t("g.upload_photo") }}
              </p>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6" class="px-xl-2 mx-auto">
        <b-form-group :label="$t('g.id_number_image')" class="mb-2">
          <validation-provider #default="{ errors }" name="id number image">
            <div class="image-input-area">
              <b-form-file
                :state="errors.length > 0 ? false : null"
                class="image-input"
                ref="id_number_image"
                v-model="form_data.id_number_image"
                accept="image/*"
              />
              <div
                class="image-icon"
                style="overflow:hidden"
                :style="{
                  maxWidth: form_data.id_number_image ? '100%' : '',
                  maxHeight: form_data.id_number_image ? '100%' : '',
                }"
              >
                <img
                  v-if="
                    !form_data.id_number_image ||
                    form_data.id_number_image.length == 0
                  "
                  :src="idCardIcon"
                  alt="Cover"
                />
                <img v-else :src="idUrl" alt="Cover" />
              </div>
              <p
                v-if="
                  !form_data.id_number_image ||
                  form_data.id_number_image.length == 0
                "
                class="image-text mb-0"
              >
                {{ $t("g.upload_photo") }}
              </p>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <GmapMap
          :center="{ lat: form_data.lat, lng: form_data.lng }"
          :zoom="7"
          map-type-id="terrain"
          style="width: 100%; height: 250px; margin-bottom: 30px"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false,
          }"
          @click="mark"
        >
          <GmapMarker
            :position="{ lat: form_data.lat, lng: form_data.lng }"
            :clickable="true"
            @click="cleanMark"
          />
        </GmapMap>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormDatepicker,
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormDatepicker,
  },
  props: ["form_data"],

  data() {
    return {
      imageInputIcon: require("@/assets/images/auth/image_input_icon.svg"),
      editIcon: require("@/assets/images/icons/pencil.svg"),
      imageIcon: require("@/assets/images/icons/photo.png"),
      idCardIcon: require("@/assets/images/pages/idCard.png"),
      position: {
        lat: 0,
        lng: 0,
      },
      idUrl: null,
      commercialUrl: null,
    };
  },
  mounted() {
    this.getUserLocation();
  },
  methods: {
    editImage(item) {
      if (item === "idCardCommercial") {
        this.form_data.commercial_number_image = null;
      } else {
        this.form_data.id_number_image = null;
      }
    },
    mark(event) {
      this.form_data = {
        ...this.form_data,
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    cleanMark() {
      this.position = {
        lat: 0,
        lng: 0,
      };
    },
    getUserLocation() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form_data.lat = position.coords.latitude;
            this.form_data.lng = position.coords.longitude;
          },
          (error) => {
            console.error(error.message);
          }
        );
      }
    },
  },
  watch: {
    "form_data.commercial_number_image": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.commercialUrl = URL.createObjectURL(val);
      }
    },
    "form_data.id_number_image": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.idUrl = URL.createObjectURL(val);
      }
    },
  },
};
</script>

<style></style>
