<template>
  <loading v-if="is_loading" />
  <div v-else class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <b-col lg="3" xl="4" class="d-none d-lg-flex align-items-center p-5">
      </b-col>

      <b-col lg="9" xl="8" class="d-flex align-items-center auth-bg px-2 pb-5">
        <b-col sm="12" md="10" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold store-info" title-tag="h2">
            {{ $t("g.store_information") }}
          </b-card-title>
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validationForm"
            >
              <InfoStore v-if="step === 1" :form_data="form_data" />

              <DocStore v-else :form_data="form_data" />

              <b-button
                :disabled="isPending"
                type="submit"
                variant="primary"
                block
              >
                {{ step === 1 ? $t("g.next") : $t("g.create_store") }}
              </b-button>
            </b-form>
          </validation-observer>
          <b-button @click="logout" variant="outline-primary mt-3" block>
            {{ step === 1 ? $t("g.logout") : $t("g.back") }}
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import InfoStore from "@/components/authentication/createNewVendor/infoStore/InfoStoreComponent.vue";
import DocStore from "@/components/authentication/createNewVendor/docStore/DocStoreComponent.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    loading,
    OverlayComponent,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    InfoStore,
    DocStore,
  },
  data() {
    return {
      form_data: {
        store_name: null,
        header: null,
        logo: null,
        about: null,
        commercial_number: null,
        commercial_number_image: null,
        id_number: null,
        id_expiry_date: null,
        id_number_image: null,
        lat: 0,
        lng: 0,
      },
      is_loading: true,
      overlay_loading: false,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logoImg: require("@/assets/images/avatars/1-small.png"),
      required,
      email,
      isPending: false,
      step: 1,
    };
  },
  mounted() {
    this.is_loading = false;
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async validationForm() {
      this.$refs.loginValidation.validate().then(async (success) => {
        if (success) {
          if (this.step === 2) {
            this.overlay_loading = true;
            this.isPending = true;

            const formData = new FormData();
            for (const key in this.form_data) {
              if (this.form_data[key])
                formData.append(key, this.form_data[key]);
            }
            try {
              const res = await this.$http.post("web/vendors", formData);

              if (res.status === 200) {
                await this.$store.dispatch("FETCH_DATA_USER");
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                await this.$router.push({ name: "vendor-pending" });
              }
              this.overlay_loading = false;
            } catch (err) {
              this.$helpers.handleError(err);
              this.overlay_loading = false;
            } finally {
              this.isPending = false;
              this.overlay_loading = false;
            }
          } else {
            this.step = 2;
          }
        }
      });
    },

    logout() {
      if (this.setp === 1) {
        localStorage.clear();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t(`g.good_bye`),
            icon: "CheckCircleIcon",
            variant: "warning",
            text: this.$t(`g.you_have_successfully_logged_out`),
          },
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else {
        this.setp = 1;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
</style>
